export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-chart'
    },
    {
      name: 'Audiences',
      url: '/audiences/closed',
      icon: 'icon-people'
    },
    {
      name: 'Campaigns',
      url: '/campaigns',
      icon: 'icon-speech'
    },
    {
      name: 'Reports',
      url: '/reports',
      icon: 'icon-graph',
    },
    {
      name: 'Coupons Portal',
      url: '/c',
      icon: 'icon-tag'
    },
    {
      name: 'Super Admin',
      url: '/sadmin',
      icon: 'icon-list'
    },
    {
      name: 'Settings',
      url: '/admin',
      icon: 'icon-settings'
    },
  ]
};
